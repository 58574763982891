(function () {
    'use strict';

    /**
     * @ngdoc object
     * @name facilities.facility.controller:FacilityCtrl
     *
     * @description
     *
     */
    angular
    .module('neo.home.facilities.facility')
    .controller('FacilityCtrl', FacilityCtrl);

    function FacilityCtrl(uiGmapGoogleMapApi, towns, townhalls, $scope, Facilities, $mdToast, $filter, facility, $stateParams, AclService, $mdDialog, $state) {
      var vm = this;
      vm.ctrlName = 'FacilityCtrl';

      vm.hasPermission = AclService.can("FACILITY", "U");
      vm.can = AclService.can;

      vm.facility = angular.copy(facility);

      vm.edit = false;

      vm.towns = towns;
      vm.townHalls = townhalls;
      if (vm.townHalls.length === 1) {
        vm.facility.townHall = vm.townHalls[0];
      } else if (vm.townHalls.length > 1) {
        vm.facility.townHall = findItemById(vm.townHalls, vm.facility.idTownHall);
      }

      vm.buttons = {
        dial: true,
        buttonImage: 'images/ic_more_vert_white_48px.svg',
        tooltip: 'Accions',
        actions: []
      };

      if (vm.can('FACILITY', 'U')) {
        vm.buttons.actions.push({
          changeState: false,
          execute: enableEdit,
          buttonImage: 'images/ic_edit_black_48px.svg',
          tooltip: $filter('translate')('EDIT')
        })
      }

      if (vm.can('FACILITY', 'D')) {
        vm.buttons.actions.push({
          changeState: false,
          execute: deleteFacility,
          buttonImage: 'images/ic_delete_white_48px.svg',
          attrClass: 'md-warn',
          tooltip: $filter('translate')('DELETE')
        })
      }

      vm.disableEdit = function () {
        vm.facility = angular.copy(facility);
        vm.edit = false;
        actionButton.dial = true;
      };

      function enableEdit() {
        vm.edit = true;
        vm.buttons.dial = false;
      }

      function deleteFacility() {

        $mdDialog.show({
          controller: function DialogController($scope, $mdDialog, $filter) {
            $scope.alertText = $filter('translate')('ALERT');
            $scope.textContent = $filter('translate')('FACILITIES.CONFIRM_DELETE');
            $scope.cancelText = $filter('translate')('CANCEL');
            $scope.deleteText = $filter('translate')('DELETE');

            $scope.hide = function () {
              $mdDialog.hide();
            };

            $scope.cancel = function () {
              $mdDialog.cancel();
            };

            $scope.delete = function () {
              //then
              $mdDialog.hide();
            };
          },
          templateUrl: 'home/championships/championship/delete-confirmation.tmpl.html',
          parent: angular.element(document.body),
          clickOutsideToClose: true
        })
        .then(function () {
          Facilities.delete({id: vm.facility.id},
            function () {
              $mdToast.show(
                $mdToast.simple()
                .textContent($filter('translate')('FACILITIES.SUCCESSFUL_DELETE'))
                .position('bottom left')
                .hideDelay(3000));
              $state.go('home.facilities', {}, {reload: true});
            },
            function (error) {
              $mdToast.show(
                $mdToast.simple()
                .textContent($filter('translate')('ERROR_BAD_REQUEST'))
                .position('bottom left')
                .hideDelay(3000));
            }
          )
        });
      }

      vm.submitFacility = function () {
        if (vm.hasPermission) {
          vm.facility.idTownHall = vm.facility.townHall.id;
          Facilities.update({id: $stateParams.facilityId}, vm.facility, function () {
              $mdToast.show(
                $mdToast.simple()
                  .textContent($filter('translate')('FACILITIES.SUCCESSFUL_UPDATE'))
                .position('bottom left')
                .hideDelay(3000));
              vm.edit = false;
              vm.buttons.dial = true;
            },
            function (error) {
              var message;
              if (error.status === 404) {
                message = 'ERROR_NON_EXISTING_TOWNHALL';
              } else {
                message = 'ERROR_BAD_REQUEST';
              }
              $mdToast.show(
                $mdToast.simple()
                .textContent($filter('translate')(message))
                .position('bottom left')
                .hideDelay(3000)
              );
            });
        }
      };

      vm.markerCoords = {
        latitude: vm.facility.latitude,
        longitude: vm.facility.longitude
      };

      uiGmapGoogleMapApi.then(function (maps) {
        maps.visualRefresh = true;
        vm.geocoder = new maps.Geocoder();
      });


      vm.map = {
        center: {
          latitude: vm.markerCoords.latitude,
          longitude: vm.markerCoords.longitude
        },
        zoom: 12,
        options: {
          tilt: 45
        },
        events: {
          click: function (mapModel, eventName, eventArgs) {
            setCoordenatesWhenEditMode(eventArgs[0].latLng.lat(), eventArgs[0].latLng.lng());
          }
        },
        markers: [{
          id: 1,
          latitude: vm.markerCoords.latitude,
          longitude: vm.markerCoords.longitude
        }
        ]
      };

      vm.searchLocation = function () {
        if (vm.edit === true) {
          if (!angular.isUndefined(vm.facility.address) && !angular.isUndefined(vm.facility.zipCode) && !angular.isUndefined(vm.facility.idTown)) {
            var address = vm.facility.address + "," + vm.facility.zipCode + " " + findItemById(vm.towns, vm.facility.idTown).name;
            vm.geocoder.geocode({'address': address}, function (results, status) {
              if (!results.isUndefined || results.size() > 0) {
                setCoordenatesWhenEditMode(results[0].geometry.location.lat(), results[0].geometry.location.lng());
                vm.map.center.latitude = vm.markerCoords.latitude;
                vm.map.center.longitude = vm.markerCoords.longitude;
              }
            })
          }
        }
      };

      function findItemById(collection, itemId) {
        return _.find(collection, function (i) {
          return i.id === itemId;
        });
      }

      function setCoordenatesWhenEditMode(latitude, longitude) {
        if (vm.edit === true) {
          vm.markerCoords.latitude = vm.facility.latitude = latitude;
          vm.markerCoords.longitude = vm.facility.longitude = longitude;
          vm.map.markers.pop();
          vm.map.markers.push({
            id: 1,
            latitude: vm.markerCoords.latitude,
            longitude: vm.markerCoords.longitude
          });
          $scope.$evalAsync();
        }
      }

    }
  }()
);
